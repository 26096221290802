import {
  ReactNode,
  createContext,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  NavigateOptions,
  To,
  useLocation, // eslint-disable-next-line custom-rules/no-use-navigate
  useNavigate,
  useSearchParams,
} from 'react-router-dom'

import UrlUtils from '@utils/url'

export interface INavigateToProps {
  path: To
  replace?: NavigateOptions['replace']
  state?: NavigateOptions['state']
  trimLastPath?: boolean
}

interface INavigateBackProps {
  fallbackPath: string
  ignorePreviousUrlQueryParams?: boolean
  state?: NavigateOptions['state']
}

interface IChangeUrlSearchParamsProps {
  params: Record<string, string> | URLSearchParams
  replace?: boolean
}

interface IChangeUrlPathProps {
  newPath: string
  replace?: boolean
}

interface NavigationContextProps {
  readonly history: readonly string[]
  navigateTo: ({ path, replace, state }: INavigateToProps) => void
  navigateBack: ({
    fallbackPath,
    state,
    ignorePreviousUrlQueryParams,
  }: INavigateBackProps) => void
  changeUrlSearchParams: ({
    params,
    replace,
  }: IChangeUrlSearchParamsProps) => void
  changeUrlPath: ({ newPath, replace }: IChangeUrlPathProps) => void
}

export const NavigationContext = createContext<
  NavigationContextProps | undefined
>(undefined)

const NavigationProvider = memo(({ children }: { children: ReactNode }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [_, setSearchParams] = useSearchParams()
  const [history, setHistory] = useState<string[]>([])

  const lockPopHistory = useRef(false)

  interface IShouldUpdatePathParams {
    newPath: string
    currentHistory: string[]
    replace: boolean
  }

  const shouldUpdatePath = useCallback(
    ({ newPath, currentHistory, replace }: IShouldUpdatePathParams) => {
      const LAST_ITEM_INDEX = 1
      const SECOND_TO_LAST_ITEM_INDEX = 2

      const lastPath =
        currentHistory[
          currentHistory.length -
            (replace ? SECOND_TO_LAST_ITEM_INDEX : LAST_ITEM_INDEX)
        ]
      return lastPath !== newPath
    },
    []
  )

  useEffect(() => {
    const { pathname, search, hash } = location
    const fullPath = `${pathname}${search ?? ''}${hash ?? ''}`
    const isHistoryEmpty = history.length === 0

    if (
      isHistoryEmpty ||
      shouldUpdatePath({
        newPath: fullPath,
        currentHistory: history,
        replace: false,
      })
    ) {
      setHistory((prev) => [...prev, fullPath])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, shouldUpdatePath])

  const popHistory = useCallback(() => {
    if (lockPopHistory.current) {
      return
    }

    lockPopHistory.current = true
    setHistory((prevHistory) => prevHistory.slice(0, -1))

    setTimeout(() => {
      lockPopHistory.current = false
    }, 100)
  }, [])

  const navigateTo = useCallback(
    ({ path, replace, state, trimLastPath }: INavigateToProps) => {
      const options: NavigateOptions = {}

      if (trimLastPath) {
        const currentPath = location.pathname
        const basePath = currentPath.split('/').slice(0, -1).join('/')
        path = `${basePath}/${path}`.replace(/\/+/g, '/')
      }

      if (replace) {
        popHistory()
        options.replace = true
      }

      if (state) {
        options.state = state
      }

      navigate(path, ...(Object.keys(options).length > 0 ? [options] : []))
    },
    [location.pathname, navigate, popHistory]
  )

  const navigateBackRef = useRef<
    ({
      fallbackPath,
      state,
      ignorePreviousUrlQueryParams,
    }: INavigateBackProps) => void
  >(() => {})

  useEffect(() => {
    navigateBackRef.current = ({
      fallbackPath,
      state,
      ignorePreviousUrlQueryParams,
    }: INavigateBackProps) => {
      let path: string | undefined

      if (history.length > 1) {
        path = history[history.length - 2]
      } else if (fallbackPath) {
        path = fallbackPath
      }

      if (path) {
        if (ignorePreviousUrlQueryParams) {
          path = path.split('?')[0]
        }

        popHistory()
        navigate(path, { replace: true, state })
      }
    }
  }, [history, navigate, popHistory])

  const navigateBack = useCallback(
    ({
      fallbackPath,
      state,
      ignorePreviousUrlQueryParams,
    }: INavigateBackProps) => {
      navigateBackRef.current({
        fallbackPath,
        state,
        ignorePreviousUrlQueryParams,
      })
    },
    []
  )

  const changeUrlSearchParams = useCallback(
    ({ params, replace }: IChangeUrlSearchParamsProps) => {
      const options: NavigateOptions = {}

      if (replace) {
        popHistory()
        options.replace = true
      }

      setSearchParams(
        params,
        ...(Object.keys(options).length > 0 ? [options] : [])
      )
    },
    [popHistory, setSearchParams]
  )

  const changeUrlPath = useCallback(
    ({ newPath, replace = true }: IChangeUrlPathProps) => {
      if (replace) {
        const changeLastPathToNewPath = () =>
          setHistory((currentHistory) => {
            if (!shouldUpdatePath({ newPath, currentHistory, replace: true })) {
              return currentHistory.slice(0, -1)
            }

            const newHistory = [...currentHistory]
            newHistory[newHistory.length - 1] = newPath

            return newHistory
          })

        // this is important to wait for the history to be updated before the new path is set
        setTimeout(changeLastPathToNewPath, 0)
      }

      UrlUtils.changeUrlPath({ newPath })
    },
    [shouldUpdatePath]
  )

  return (
    <NavigationContext.Provider
      value={{
        history,
        navigateTo,
        navigateBack,
        changeUrlSearchParams,
        changeUrlPath,
      }}
    >
      {children}
    </NavigationContext.Provider>
  )
})

export { NavigationProvider }
