import { ReactNode, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { LoadingButton, LoadingButtonProps } from '@mui/lab'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  SxProps,
  Theme,
} from '@mui/material'

import {
  buttonSx,
  contentSx,
  contentTextSx,
  containerSx as styleContainerSx,
  titleSx,
} from './styles'

export interface IAlertDialogProps {
  isOpen: boolean
  isLoading?: boolean
  criticalOperation?: boolean
  disableCloseOnBackdropClick?: boolean
  disableCloseOnEscapeKeyDown?: boolean
  title: string | ReactNode
  text?: string | ReactNode
  component?: JSX.Element
  cancelLabel?: string
  confirmLabel: string
  containerSx?: SxProps<Theme>
  cancelButtonProps?: any
  confirmButtonProps?: LoadingButtonProps
  showConfirmButton?: boolean
  showCancelButton?: boolean
  onClose?: () => void
  onCancel: () => void
  onSecondButtonClick?: () => void
  onConfirm: () => void
}

const ConfirmationDialog = ({
  isOpen,
  isLoading,
  title,
  component,
  text,
  criticalOperation,
  cancelLabel,
  confirmLabel,
  containerSx,
  cancelButtonProps,
  confirmButtonProps,
  disableCloseOnBackdropClick,
  disableCloseOnEscapeKeyDown,
  showConfirmButton = true,
  showCancelButton = true,
  onClose,
  onCancel,
  onSecondButtonClick,
  onConfirm,
}: IAlertDialogProps) => {
  const { t } = useTranslation()
  const buttonClickedRef = useRef<'cancel' | 'confirm'>()

  const handleOnClose = (_: any, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (disableCloseOnBackdropClick && reason && reason === 'backdropClick') {
      return
    }

    if (disableCloseOnEscapeKeyDown && reason && reason === 'escapeKeyDown') {
      return
    }

    onClose ? onClose() : onCancel()
  }

  const handleOnCancelButtonClick = () => {
    buttonClickedRef.current = 'cancel'
    onSecondButtonClick ? onSecondButtonClick() : onCancel()
  }

  const handleOnConfirmButtonClick = () => {
    buttonClickedRef.current = 'confirm'
    onConfirm()
  }

  const finalConfirmButtonColor =
    confirmButtonProps?.color || (criticalOperation ? 'error' : 'primary')

  return (
    <div>
      <Dialog
        data-testid="confirmation-dialog"
        open={isOpen}
        onClose={handleOnClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: { ...styleContainerSx, ...containerSx } as SxProps<Theme>,
        }}
      >
        <DialogTitle
          data-testid="alert-dialog-title"
          id="alert-dialog-title"
          sx={titleSx}
        >
          {title}
        </DialogTitle>
        <DialogContent sx={contentSx}>
          {!component ? (
            <DialogContentText
              data-testid="alert-dialog-description"
              id="alert-dialog-description"
              sx={contentTextSx}
            >
              {text}
            </DialogContentText>
          ) : (
            <>{component}</>
          )}
        </DialogContent>
        <DialogActions>
          {showCancelButton && (
            <LoadingButton
              data-testid="confirmation-dialog-cancel-button"
              variant="text"
              sx={buttonSx}
              size="medium"
              loading={isLoading && buttonClickedRef.current === 'cancel'}
              disabled={isLoading}
              {...cancelButtonProps}
              onClick={handleOnCancelButtonClick}
            >
              {cancelLabel ?? t('Cancel')}
            </LoadingButton>
          )}
          {showConfirmButton && (
            <LoadingButton
              data-testid="confirmation-dialog-confirm-button"
              sx={buttonSx}
              variant="contained"
              {...confirmButtonProps}
              size="medium"
              loading={isLoading && buttonClickedRef.current === 'confirm'}
              disabled={isLoading}
              color={finalConfirmButtonColor}
              onClick={handleOnConfirmButtonClick}
            >
              {confirmLabel}
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
    </div>
  )
}

export { ConfirmationDialog }
