import { AhrefScript } from '@scripts/ahrefs'
import { HubSpotScript } from '@scripts/hubspot'
import { ZoomInfoScript } from '@scripts/zoom-info'

const ThirdPartyScripts = () => (
  <>
    <HubSpotScript />
    <ZoomInfoScript />
    <AhrefScript />
  </>
)

export { ThirdPartyScripts }
