import { useTranslation } from 'react-i18next'

import { Alert, Snackbar, Typography } from '@mui/material'

import responsiveHook from '@hooks/useResponsive'
import toastHook from '@hooks/useToast'

export const Toast = () => {
  const {
    remove,
    message: toastMessage,
    isVisible: isToastVisible,
  } = toastHook.useToast()
  const { t } = useTranslation()

  const { isMobile } = responsiveHook.useResponsive()

  return (
    <Snackbar
      data-testid="snackbar"
      aria-label={t('Alert')}
      open={isToastVisible}
      autoHideDuration={6_000}
      onClose={remove}
      onClick={remove}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: isMobile ? 'center' : 'right',
      }}
      sx={{
        zIndex: 999_999,
        ...(isMobile ? { bottom: '0 !important' } : undefined),
        width: { xs: '100%', md: 'auto' },
        padding: { xs: '1.6rem', md: 0 },
        '& .MuiPaper-root': {
          alignItems: 'center',
        },
      }}
    >
      <Alert
        variant="filled"
        severity={toastMessage?.type}
        sx={{
          width: '100%',
          zIndex: 999999,
          color: (theme) => theme.palette.primary.contrastText,
        }}
        action={toastMessage?.action}
      >
        <Typography
          variant="body1"
          sx={{
            whiteSpace: 'pre-line',
            color: (theme) => theme.palette.primary.contrastText,
          }}
        >
          {toastMessage?.message}
        </Typography>
      </Alert>
    </Snackbar>
  )
}
