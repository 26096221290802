import { useEffect } from 'react'

import EnvironmentUtils from '@utils/environments'
import ZoomInfoUtils from '@utils/zoom-info'

const ZoomInfoScript = () => {
  const addZoomInfoScript = () => {
    const script = ZoomInfoUtils.buildScript()

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }

  useEffect(() => {
    if (EnvironmentUtils.isProduction()) {
      return addZoomInfoScript()
    }
  }, [])

  return null
}

export { ZoomInfoScript }
