import { useEffect } from 'react'

import AHrefUtils from '@utils/ahrefs'
import EnvironmentUtils from '@utils/environments'

const AhrefScript = () => {
  const addAhrefScript = () => {
    const script = AHrefUtils.buildScript()

    if (!script) {
      return
    }

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }

  useEffect(() => {
    if (EnvironmentUtils.isProduction()) {
      return addAhrefScript()
    }
  }, [])

  return null
}

export { AhrefScript }
