import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Button, List, SxProps, Theme, Typography } from '@mui/material'

import AddIcon from '@mui/icons-material/Add'

import {
  differenceInDays,
  format,
  isToday,
  isYesterday,
  parseISO,
} from 'date-fns'

import dialogHook from '@hooks/useDialog'
import errorHandlerHook from '@hooks/useErrorHandler'
import toastHook from '@hooks/useToast'

import ChatbotService from '@services/chatbot'

import { ConfirmationDialog } from '@components/ConfirmationDialog'

import { ChatbotConversation } from '@utils/types'

import {
  confirmationDialogContainerSx,
  conversationListSx,
  listSx,
  newConversationButtonSx,
  periodContainerSx,
  periodSubtitleSx,
} from './styles'

import { ConversationItem } from './ConversationItem'

type ConversationListProps = {
  conversations: ChatbotConversation[]
  sx?: SxProps<Theme>
  onSelectConversation: (data: { conversationId: string }) => void
  onNewConversation: () => void
  onConversationDeleted: (conversationId: string) => void
}

interface CategorizedItems {
  today: ChatbotConversation[]
  yesterday: ChatbotConversation[]
  last2weeks: ChatbotConversation[]
  last7days: ChatbotConversation[]
  last30days: ChatbotConversation[]
  months: Record<string, ChatbotConversation[]>
}

const ConversationList = ({
  conversations,
  sx,
  onSelectConversation,
  onNewConversation,
  onConversationDeleted,
}: ConversationListProps) => {
  const { t } = useTranslation()
  const { handleError } = errorHandlerHook.useErrorHandler()
  const { show: showToast } = toastHook.useToast()

  const [selectedConversation, setSelectedConversation] =
    useState<ChatbotConversation>()
  const [isDeletingChatConversation, setIsDeletingChatConversation] =
    useState(false)

  const deleteChatConversationDialog = dialogHook.useDialog()

  const categorizeChatItems = ({
    items,
  }: {
    items: ChatbotConversation[]
  }): CategorizedItems => {
    const today = new Date()
    const result: CategorizedItems = {
      today: [],
      yesterday: [],
      last2weeks: [],
      last7days: [],
      last30days: [],
      months: {},
    }

    items.forEach((item) => {
      const itemDate = parseISO(new Date(item.createdDate).toISOString())
      const diffInDays = differenceInDays(today, itemDate)

      if (isToday(itemDate)) {
        result.today.push(item)
      } else if (isYesterday(itemDate)) {
        result.yesterday.push(item)
      } else if (diffInDays < 7) {
        result.last7days.push(item)
      } else if (diffInDays < 14) {
        result.last2weeks.push(item)
      } else if (diffInDays < 30) {
        result.last30days.push(item)
      } else {
        const monthYear = format(itemDate, 'MMMM yyyy')
        if (!result.months[monthYear]) {
          result.months[monthYear] = []
        }
        result.months[monthYear].push(item)
      }
    })

    return result
  }

  const categorizedItems = categorizeChatItems({
    items: conversations,
  })

  const handleOnSelectDeleteOption = ({
    conversation,
  }: {
    conversation: ChatbotConversation
  }) => {
    setSelectedConversation(conversation)
    deleteChatConversationDialog.open()
  }

  const handleOnDeleteChatConversation = async () => {
    try {
      setIsDeletingChatConversation(true)

      await ChatbotService.deleteConversation({
        conversationId: selectedConversation!.id,
      })

      showToast({
        type: 'success',
        message: t('The chat was deleted from your history.'),
      })

      deleteChatConversationDialog.close()

      onConversationDeleted(selectedConversation!.id)

      setSelectedConversation(undefined)
    } catch (err) {
      handleError({ exception: err })
    }

    setIsDeletingChatConversation(false)
  }

  return (
    <>
      {selectedConversation && (
        <ConfirmationDialog
          containerSx={confirmationDialogContainerSx}
          isOpen={deleteChatConversationDialog.isOpen}
          title={t('Are you sure you want to delete this chat?')}
          component={
            <Box data-testid="alert-dialog-content">
              <Typography variant="body1" component="span">
                {t('This will permanently delete')}{' '}
              </Typography>

              <Typography variant="subtitle1" component="span">
                {`"${selectedConversation.name}"`}{' '}
              </Typography>

              <Typography variant="body1" component="span">
                {t('from your chat history') + '.'}
              </Typography>
            </Box>
          }
          confirmLabel={t('Delete')}
          criticalOperation
          isLoading={isDeletingChatConversation}
          onClose={deleteChatConversationDialog.close}
          onCancel={deleteChatConversationDialog.close}
          onConfirm={handleOnDeleteChatConversation}
        />
      )}

      <Box sx={{ ...conversationListSx, ...sx } as SxProps}>
        <Box>
          <Button
            data-testid="new-conversation-button"
            variant="outlined"
            color="primary"
            fullWidth
            sx={newConversationButtonSx}
            onClick={onNewConversation}
            startIcon={<AddIcon />}
          >
            {t('New Chat')}
          </Button>
        </Box>

        <List sx={listSx}>
          {categorizedItems.today?.length > 0 && (
            <Box sx={periodContainerSx} data-testid="today-period-container">
              <Typography
                variant="subtitle2"
                sx={periodSubtitleSx}
                data-testid="period-title"
              >
                {t('Today')}
              </Typography>

              {categorizedItems.today.map((v) => (
                <ConversationItem
                  key={v.id}
                  conversation={v}
                  onSelectDeleteOption={handleOnSelectDeleteOption}
                  onSelectConversation={onSelectConversation}
                />
              ))}
            </Box>
          )}

          {categorizedItems.yesterday?.length > 0 && (
            <Box
              sx={periodContainerSx}
              data-testid="yesterday-period-container"
            >
              <Typography
                variant="subtitle2"
                sx={periodSubtitleSx}
                data-testid="period-title"
              >
                {t('Yesterday')}
              </Typography>

              {categorizedItems.yesterday.map((v) => (
                <ConversationItem
                  key={v.id}
                  conversation={v}
                  onSelectDeleteOption={handleOnSelectDeleteOption}
                  onSelectConversation={onSelectConversation}
                />
              ))}
            </Box>
          )}

          {categorizedItems.last7days?.length > 0 && (
            <Box
              sx={periodContainerSx}
              data-testid="last-7-days-period-container"
            >
              <Typography
                variant="subtitle2"
                sx={periodSubtitleSx}
                data-testid="period-title"
              >
                {t('Last 7 days')}
              </Typography>

              {categorizedItems.last7days.map((v) => (
                <ConversationItem
                  key={v.id}
                  conversation={v}
                  onSelectDeleteOption={handleOnSelectDeleteOption}
                  onSelectConversation={onSelectConversation}
                />
              ))}
            </Box>
          )}

          {categorizedItems.last2weeks?.length > 0 && (
            <Box
              sx={periodContainerSx}
              data-testid="last-2-weeks-period-container"
            >
              <Typography
                variant="subtitle2"
                sx={periodSubtitleSx}
                data-testid="period-title"
              >
                {t('Last 2 Weeks')}
              </Typography>

              {categorizedItems.last2weeks.map((v) => (
                <ConversationItem
                  key={v.id}
                  conversation={v}
                  onSelectDeleteOption={handleOnSelectDeleteOption}
                  onSelectConversation={onSelectConversation}
                />
              ))}
            </Box>
          )}

          {categorizedItems.last30days?.length > 0 && (
            <Box
              sx={periodContainerSx}
              data-testid="last-30-days-period-container"
            >
              <Typography
                variant="subtitle2"
                sx={periodSubtitleSx}
                data-testid="period-title"
              >
                {t('Last 30 days')}
              </Typography>

              {categorizedItems.last30days.map((v) => (
                <ConversationItem
                  key={v.id}
                  conversation={v}
                  onSelectDeleteOption={handleOnSelectDeleteOption}
                  onSelectConversation={onSelectConversation}
                />
              ))}
            </Box>
          )}

          {Object.entries(categorizedItems.months).map(([monthYear, items]) => (
            <Box
              sx={periodContainerSx}
              key={monthYear}
              data-testid="month-period-container"
            >
              <Typography
                variant="subtitle2"
                sx={periodSubtitleSx}
                data-testid="period-title"
              >
                {monthYear}
              </Typography>
              {items.map((v) => (
                <ConversationItem
                  key={v.id}
                  conversation={v}
                  onSelectDeleteOption={handleOnSelectDeleteOption}
                  onSelectConversation={onSelectConversation}
                />
              ))}
            </Box>
          ))}
        </List>
      </Box>
    </>
  )
}

export { ConversationList }
