import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import * as Sentry from '@sentry/react'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { Auth } from 'aws-amplify'

import chatHook from '@hooks/useChat'
import errorHandlerHook, { IErrorCallback } from '@hooks/useErrorHandler'
import localStorageHook from '@hooks/useLocalStorage'
import navigationHook from '@hooks/useNavigation'
import toastHook from '@hooks/useToast'

import AdminService, {
  ICreateOrUpdateProfile as IAdminUpdateProfile,
} from '@services/admin'
import AuthService from '@services/auth'
import BuyerService from '@services/buyer'
import ChangelogService from '@services/changelog'
import VendorService from '@services/vendor'

import AdminUtils from '@utils/admins'
import { errorMessages, errorTypes } from '@utils/errors'
import { exceptions, exceptionsTypes } from '@utils/exceptions'
import {
  LOCAL_STORAGE_USER_SIGNOUT_EVENT_KEY,
  LOCAL_STORAGE_USER_TYPE_KEY,
} from '@utils/local-storage'
import {
  LEGACY_BUYER_COMPLETION_PROFILE_CARD_REMOVED,
  LEGACY_BUYER_MATCHES_COUNT_CARD_REMOVED,
  LEGACY_VENDOR_ARCHIVED_SOLUTIONS_COUNT_CARD_REMOVED,
  LEGACY_VENDOR_INCOMPLETE_SOLUTIONS_COUNT_CARD_REMOVED,
  SESSION_STORAGE_IMPERSONATED_BY_KEY,
  SESSION_STORAGE_IMPERSONATION_KEY,
  SESSION_STORAGE_USER_TYPE_KEY,
} from '@utils/session-storage'
import {
  AdminAction,
  ChangelogAction,
  CustomUserAttribute,
  IAdmin,
  IAuthenticatedSignInResponse,
  IBuyer,
  IChallengeSignInResponse,
  ICompany,
  IImpersonatedUser,
  IVendor,
  UserStatusOption,
  UserType,
} from '@utils/types'

interface ISendVerifyCodeEmail {
  email?: string
  password?: string
}

interface IVerifyEmail {
  code: string
  email?: string
  password?: string
}

interface IBuyerSignUp {
  id?: string
  firstName: string
  lastName: string
  companyName: string
  email: string
  password: string
  legacy?: boolean
  incentiveId?: string
}

export interface IVendorSignUp {
  id?: string
  firstName: string
  lastName: string
  companyName: string
  email: string
  password: string
  legacy?: boolean
  promotionCodeId?: string
  offerId?: string
  partnerId?: string
  transactionId?: string
  incentiveId?: string
}

interface IConfirmUser {
  firstName?: string
  lastName?: string
  email: string
  code: string
  userType: UserType
}

interface IActivateUser {
  userType: UserType
  incentiveId?: string
}

interface ICompleteNewPassword {
  password: string
}

interface IResendVerificationEmail {
  email: string
  userType: UserType
}

interface IForgotPassword {
  email: string
  userType: UserType
}

interface IResetPassword {
  email: string
  code: string
  newPassword: string
  userType: UserType
}
interface IChangePassword {
  userType: UserType
  currentPassword: string
  newPassword: string
}

interface ISignIn {
  email: string
  password: string
  userType: UserType
}

interface IUpdateProfile {
  firstName: string
  lastName: string
  officialTitle: string
  personalEmail?: string
  phone?: string
  company?: ICompany
  avatar?: string
}

interface IAddUserType {
  convertingToUserType: UserType
  email?: string
  password?: string
  companyType?: string
}

interface IIsAdminAllowed {
  action: AdminAction
}

type ThirdPartyServiceUserImpersonatedBy = Pick<
  IAdmin,
  'userId' | 'email' | 'firstName' | 'lastName' | 'role'
>

interface IThirdPartyServiceUser {
  id: string
  userId: string
  firstName?: string
  lastName?: string
  email: string
  type: UserType
  impersonatedBy?: ThirdPartyServiceUserImpersonatedBy
}

export type AuthContextState = {
  authenticatedUser?: CognitoUser
  admin?: IAdmin
  buyer?: IBuyer
  vendor?: IVendor
  isLoadingVendor: boolean
  isLoadingBuyer: boolean
  activeUserType?: UserType
  impersonatedUser?: IImpersonatedUser
  impersonatedBy?: IAdmin
  isLoadingImpersonation?: boolean
  isFetchingUserData: boolean
  isSigningOut: boolean
  refreshBuyerProfile: () => Promise<void>
  refreshVendorProfile: () => Promise<void>
  updateVendorChatStatus: (
    data: Pick<IVendor, 'chatStatus' | 'chatStatusOfflineAutomaticResponse'>
  ) => void
  updateAdminChatStatus: (
    data: Pick<IAdmin, 'chatStatus' | 'chatStatusOfflineAutomaticResponse'>
  ) => void
  buyerSignUp(data: IBuyerSignUp): Promise<void>
  vendorSignUp(data: IVendorSignUp): Promise<void>
  confirmUser(data: IConfirmUser): Promise<void>
  activateUser(data: IActivateUser): Promise<void>
  signIn(data: ISignIn): Promise<void>
  signOut(): Promise<void>
  setIsSigningOut: Dispatch<SetStateAction<boolean>>
  forgotPassword(data: IForgotPassword): Promise<void>
  resetPassword(data: IResetPassword): Promise<void>
  changePassword(data: IChangePassword): Promise<void>
  resendVerificationCodeEmail(data: IResendVerificationEmail): Promise<void>
  completeNewPassword(data: ICompleteNewPassword): Promise<void>
  sendVerifyCodeEmail(data: ISendVerifyCodeEmail): Promise<void>
  sendAdminVerifyCodeEmail(data: ISendVerifyCodeEmail): Promise<void>
  verifyEmail(data: IVerifyEmail): Promise<void>
  saveProfile(
    data: Omit<IUpdateProfile, 'avatar'> & { avatar?: File | null }
  ): Promise<void>
  saveAdminProfile(data: IAdminUpdateProfile): Promise<void>
  isAdmin(): boolean
  isBuyer(): boolean
  isVendor(): boolean
  isVisitor(): boolean
  getCurrentUser(): IAdmin | IBuyer | IVendor | null | undefined
  isUserSoftDeleted(): boolean
  recoverUserAccount(): Promise<void>
  addUserType(data: IAddUserType): Promise<void>
  changeActiveUserTypeForSession(userType: UserType): void
  changeActiveUserTypeForLocal(userType: UserType): void
  impersonate(impersonatedUser?: IImpersonatedUser): Promise<void>
  isAdminAllowed(data: IIsAdminAllowed): boolean
  switchToBuyerProfile({ redirect }: { redirect?: string }): Promise<void>
}

export const AuthContext = createContext<AuthContextState>(
  {} as AuthContextState
)

const cognitoChallenges = {
  NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
}

interface IAuthProviderProps {
  authContextOverride?: Partial<AuthContextState>
  children?: ReactNode
}

const AuthProvider = ({
  authContextOverride,
  children,
}: IAuthProviderProps) => {
  const { show: showToast } = toastHook.useToast()
  const { t } = useTranslation()
  const { handleError } = errorHandlerHook.useErrorHandler()
  const { disconnectFromChat } = chatHook.useChat()
  const { navigateTo } = navigationHook.useNavigation()

  const { setValue: setLocalStorageUserSignOutEvent } =
    localStorageHook.useLocalStorage<string | undefined>(
      LOCAL_STORAGE_USER_SIGNOUT_EVENT_KEY,
      undefined
    )

  const [admin, setAdmin] = useState<IAdmin | undefined>(
    authContextOverride?.admin
  )
  const [buyer, setBuyer] = useState<IBuyer | undefined>(
    () => authContextOverride?.buyer
  )
  const [vendor, setVendor] = useState<IVendor | undefined>(
    () => authContextOverride?.vendor
  )

  const [isLoadingVendor, setIsLoadingVendor] = useState(false)
  const [isLoadingBuyer, setIsLoadingBuyer] = useState(false)
  const [isSigningOut, setIsSigningOut] = useState(false)

  const [tempCompletePasswordCognitoUser, setTempCompletePasswordCognitoUser] =
    useState<CognitoUser>()
  const [authenticatedUser, setAuthenticatedUser] = useState<
    CognitoUser | undefined
  >(authContextOverride?.authenticatedUser)
  const [activeUserType, setActiveUserType] = useState<UserType | undefined>(
    () => {
      if (authContextOverride?.activeUserType) {
        return authContextOverride?.activeUserType
      }

      const userTypeStored =
        sessionStorage.getItem(SESSION_STORAGE_USER_TYPE_KEY) ||
        localStorage.getItem(LOCAL_STORAGE_USER_TYPE_KEY)
      return userTypeStored ? (userTypeStored as UserType) : UserType.BUYER
    }
  )
  const [impersonatedUser, setImpersonatedUser] = useState<
    IImpersonatedUser | undefined
  >(() => {
    const impersonationStr = sessionStorage.getItem(
      SESSION_STORAGE_IMPERSONATION_KEY
    )

    return impersonationStr
      ? {
          ...JSON.parse(impersonationStr),
        }
      : undefined
  })
  const [isLoadingImpersonation, setIsLoadingImpersonation] = useState(() =>
    window.location.href.includes('/admin/impersonate')
  )

  const getImpersonatedByFromLocalStorage = useCallback(() => {
    const impersonationBySessionStorage = sessionStorage.getItem(
      SESSION_STORAGE_IMPERSONATED_BY_KEY
    )

    return impersonationBySessionStorage
      ? JSON.parse(impersonationBySessionStorage)
      : undefined
  }, [])

  const [impersonatedBy, setImpersonatedBy] = useState<IAdmin | undefined>(
    getImpersonatedByFromLocalStorage
  )

  const [isFetchingUserData, setIsFetchingUserData] = useState(true)

  const changeActiveUserTypeForSession = useCallback((userType: UserType) => {
    setActiveUserType(userType)
    sessionStorage.setItem(SESSION_STORAGE_USER_TYPE_KEY, userType.toString())
  }, [])

  const changeActiveUserTypeForLocal = useCallback((userType: UserType) => {
    setActiveUserType(userType)
    localStorage.setItem(LOCAL_STORAGE_USER_TYPE_KEY, userType.toString())
  }, [])

  const triggerUserSignOutLocalStorageEvent = useCallback(() => {
    setLocalStorageUserSignOutEvent(new Date().getTime().toString())
  }, [setLocalStorageUserSignOutEvent])

  const signOut = useCallback(async () => {
    if (authContextOverride?.signOut) {
      await authContextOverride.signOut()
      return
    }

    let impersonationStoppedLogDetails: any = undefined

    if (impersonatedUser) {
      impersonationStoppedLogDetails = {
        userType: impersonatedUser.userType,
        userId: impersonatedUser.userId,
        action: ChangelogAction.IMPERSONATION_STOP,
      } as const
    }

    await disconnectFromChat()
    setIsSigningOut(true)

    setAuthenticatedUser(undefined)
    setTempCompletePasswordCognitoUser(undefined)

    setImpersonatedUser(undefined)
    setImpersonatedBy(undefined)

    sessionStorage.removeItem(SESSION_STORAGE_IMPERSONATION_KEY)
    sessionStorage.removeItem(SESSION_STORAGE_IMPERSONATED_BY_KEY)

    setActiveUserType(undefined)

    setAdmin(undefined)
    setBuyer(undefined)
    setVendor(undefined)

    if (impersonationStoppedLogDetails) {
      await saveUserImpersonationChangelog(impersonationStoppedLogDetails)
    }

    await Auth.signOut()
    clearTempSessionData()

    triggerUserSignOutLocalStorageEvent()

    Sentry.setUser(null)
    datadogLogs.clearUser()
    datadogRum.clearUser()
  }, [
    authContextOverride,
    impersonatedUser,
    disconnectFromChat,
    triggerUserSignOutLocalStorageEvent,
  ])

  const clearTempSessionData = () => {
    const sessionStorageKeysToRemove = [
      LEGACY_VENDOR_ARCHIVED_SOLUTIONS_COUNT_CARD_REMOVED,
      LEGACY_VENDOR_INCOMPLETE_SOLUTIONS_COUNT_CARD_REMOVED,
      LEGACY_BUYER_COMPLETION_PROFILE_CARD_REMOVED,
      LEGACY_BUYER_MATCHES_COUNT_CARD_REMOVED,
    ]

    sessionStorageKeysToRemove.forEach((v) => sessionStorage.removeItem(v))
  }

  const setUserProfile = useCallback(
    async (type: UserType) => {
      let signedUser: IBuyer | IAdmin | IVendor | undefined = undefined

      switch (type) {
        case UserType.ADMIN: {
          const admin = await fetchAdminProfile()

          setAdmin(admin)
          signedUser = admin

          break
        }
        case UserType.BUYER: {
          const buyer = await fetchBuyerProfile()

          setBuyer(buyer)
          signedUser = buyer

          break
        }

        case UserType.VENDOR: {
          const vendor = await fetchVendorProfile()

          setVendor(vendor)
          signedUser = vendor

          break
        }
      }

      if (signedUser && activeUserType) {
        const impersonatedBy = getImpersonatedByFromLocalStorage()

        const thirdPartyServiceUser = {
          ...signedUser,
          type: activeUserType,
          impersonatedBy,
        }

        setThirdPartyServiceUser(thirdPartyServiceUser)
      }

      setIsFetchingUserData(false)
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeUserType, showToast, signOut, t, handleError]
  )

  const fetchBuyerProfile = useCallback(async () => {
    setIsLoadingBuyer(true)
    try {
      return await BuyerService.getByUserId()
    } catch (err) {
      const userDisabledErrorCallback: IErrorCallback = {
        type: errorTypes.USER_DISABLED,
        callback: signOut,
      }

      handleError({
        exception: err,
        errorCallbacks: [userDisabledErrorCallback],
        options: {
          signOut,
        },
      })
    } finally {
      setIsLoadingBuyer(false)
    }
  }, [handleError, signOut])

  const showErrorAndSignOut = useCallback(
    ({ errorMessage }: { errorMessage: string }) => {
      showToast({
        message: errorMessage,
        type: 'error',
      })

      signOut()
    },
    [showToast, signOut]
  )

  const refreshBuyerProfile = useCallback(async () => {
    const buyer = await fetchBuyerProfile()
    setBuyer(buyer)
  }, [fetchBuyerProfile])

  const saveAdminProfile = useCallback(
    async (
      data?: Omit<IAdminUpdateProfile, 'avatar'> & {
        avatar?: File | null
      }
    ) => {
      const isEditing = !!admin?.id

      let response: any

      if (isEditing) {
        response = await AdminService.updateProfile(data!)
      } else {
        response = await AdminService.createProfile(data)
      }

      setAdmin((v) => {
        return { ...v, ...response }
      })
    },
    [admin?.id]
  )

  const fetchAdminProfile = useCallback(async () => {
    try {
      return await AdminService.getByUserId()
    } catch (err: any) {
      const notFoundErrorCallback: IErrorCallback = {
        type: errorTypes.ADMIN_NOT_FOUND,
        callback: () => {
          saveAdminProfile()
        },
      }

      const defaultErrorCallback = () => {
        navigateTo({ path: '/admin' })
      }

      handleError({
        exception: err,
        errorCallbacks: [notFoundErrorCallback],
        defaultErrorCallback,
        options: {
          skipRedirect: true,
        },
      })
    }
  }, [handleError, navigateTo, saveAdminProfile])

  const fetchVendorProfile = useCallback(async () => {
    try {
      setIsLoadingVendor(true)

      const response = await VendorService.getByUserId()

      if (!response.paymentActive) {
        const errorMessage = errorMessages[errorTypes.STRIPE_CUSTOMER_NOT_FOUND]

        showErrorAndSignOut({
          errorMessage,
        })

        return
      }

      return response
    } catch (err) {
      const userDisabledErrorCallback: IErrorCallback = {
        type: errorTypes.USER_DISABLED,
        callback: signOut,
      }

      handleError({
        exception: err,
        errorCallbacks: [userDisabledErrorCallback],
        options: {
          signOut,
        },
      })
    } finally {
      setIsLoadingVendor(false)
    }
  }, [handleError, showErrorAndSignOut, signOut])

  const refreshVendorProfile = useCallback(async () => {
    const vendor = await fetchVendorProfile()
    setVendor(vendor)
  }, [fetchVendorProfile])

  const saveImpersonatedUser = useCallback(
    (impersonatedUser: IImpersonatedUser) => {
      setImpersonatedUser(impersonatedUser)

      sessionStorage.setItem(
        SESSION_STORAGE_IMPERSONATION_KEY,
        JSON.stringify(impersonatedUser)
      )
    },
    []
  )

  const saveImpersonatedBy = useCallback((admin: IAdmin) => {
    setImpersonatedBy(admin)

    sessionStorage.setItem(
      SESSION_STORAGE_IMPERSONATED_BY_KEY,
      JSON.stringify(admin)
    )
  }, [])

  const saveImpersonationData = useCallback(
    ({
      impersonatedUser,
      impersonatedBy,
    }: {
      impersonatedUser: IImpersonatedUser
      impersonatedBy: IAdmin
    }) => {
      saveImpersonatedUser(impersonatedUser)
      saveImpersonatedBy(impersonatedBy)
    },
    [saveImpersonatedUser, saveImpersonatedBy]
  )

  const impersonate = useCallback(
    async (impersonatedUser?: IImpersonatedUser) => {
      setIsFetchingUserData(true)

      if (impersonatedUser) {
        await saveUserImpersonationChangelog({
          userType: impersonatedUser.userType,
          userId: impersonatedUser.userId,
          action: ChangelogAction.IMPERSONATION_START,
        })

        changeActiveUserTypeForSession(impersonatedUser.userType)

        saveImpersonationData({
          impersonatedUser,
          impersonatedBy: admin!,
        })

        setIsLoadingImpersonation(false)

        const pathPrefix =
          impersonatedUser.userType === UserType.VENDOR ? 'vendor' : 'buyer'

        navigateTo({ path: `/${pathPrefix}/dashboard` })
      } else {
        const wasImpersonating =
          impersonatedUser ??
          JSON.parse(
            sessionStorage.getItem(SESSION_STORAGE_IMPERSONATION_KEY) ?? '{}'
          )

        setImpersonatedBy(undefined)
        setImpersonatedUser(undefined)
        setBuyer(undefined)
        setVendor(undefined)
        changeActiveUserTypeForSession(UserType.ADMIN)

        disconnectFromChat()
        sessionStorage.removeItem(SESSION_STORAGE_IMPERSONATION_KEY)

        await saveUserImpersonationChangelog({
          userType: wasImpersonating.userType,
          userId: wasImpersonating.userId,
          action: ChangelogAction.IMPERSONATION_STOP,
        })
      }
    },
    [
      admin,
      changeActiveUserTypeForSession,
      saveImpersonationData,
      navigateTo,
      disconnectFromChat,
    ]
  )

  const saveUserImpersonationChangelog = async ({
    userType,
    userId,
    action,
  }: {
    userType: UserType
    userId: string
    action:
      | ChangelogAction.IMPERSONATION_START
      | ChangelogAction.IMPERSONATION_STOP
  }) => {
    try {
      await ChangelogService.saveUserImpersonationChangelog({
        userType,
        userId,
        action,
      })
    } catch (err) {
      Sentry.captureException(err)
    }
  }

  const getTypesForUser = useCallback((user: CognitoUser): UserType[] => {
    const types =
      user?.getSignInUserSession()?.getAccessToken()?.payload[
        'cognito:groups'
      ] || []
    return types
  }, [])

  const isAdmin = useCallback(() => {
    const isAuthenticatedUser = !!authenticatedUser
    const isAdminUserType = UserType.ADMIN === activeUserType
    const isAdminSet = !!admin

    return isAuthenticatedUser && isAdminUserType && isAdminSet
  }, [activeUserType, authenticatedUser, admin])

  const isBuyer = useCallback(
    () =>
      !!authenticatedUser &&
      !!buyer &&
      UserType.BUYER === activeUserType &&
      getTypesForUser(authenticatedUser).some(
        (type) =>
          type === UserType.BUYER ||
          impersonatedUser?.userType === UserType.BUYER
      ),
    [
      activeUserType,
      buyer,
      authenticatedUser,
      impersonatedUser?.userType,
      getTypesForUser,
    ]
  )

  const isVendor = useCallback(
    () =>
      !!authenticatedUser &&
      !!vendor &&
      UserType.VENDOR === activeUserType &&
      getTypesForUser(authenticatedUser).some(
        (type) =>
          type === UserType.VENDOR ||
          impersonatedUser?.userType === UserType.VENDOR
      ),
    [
      activeUserType,
      vendor,
      authenticatedUser,
      impersonatedUser?.userType,
      getTypesForUser,
    ]
  )

  const isVisitor = useCallback(
    () => !isFetchingUserData && !authenticatedUser,
    [authenticatedUser, isFetchingUserData]
  )

  const getCurrentUser = useCallback(() => {
    if (isBuyer()) {
      return buyer
    } else if (isVendor()) {
      return vendor
    } else if (isAdmin()) {
      return admin
    }

    return null
  }, [isBuyer, isVendor, isAdmin, buyer, vendor, admin])

  const isUserSoftDeleted = useCallback(() => {
    const user = buyer ?? vendor
    return (
      !!authenticatedUser &&
      !!user &&
      user.statusOption === UserStatusOption.DELETED_BY_USER
    )
  }, [buyer, vendor, authenticatedUser])

  const recoverUserAccount = useCallback(async () => {
    const service = isBuyer() ? BuyerService : VendorService
    await service.recoverAccount()

    // Set the user profile so that the data is not stale after the update on the authentication end
    await setUserProfile(activeUserType!)
  }, [activeUserType, isBuyer, setUserProfile])

  const fetchAuthenticatedUser = useCallback(async () => {
    try {
      const authenticatedUser = await Auth.currentAuthenticatedUser()

      setAuthenticatedUser(authenticatedUser)
    } catch (err) {
      setAuthenticatedUser(undefined)
      setIsFetchingUserData(false)
    }
  }, [])

  useEffect(() => {
    fetchAuthenticatedUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!activeUserType) {
      return
    }

    if (authenticatedUser) {
      if (
        getTypesForUser(authenticatedUser).some((t) => t === activeUserType) ||
        impersonatedUser?.userType === activeUserType
      ) {
        setUserProfile(activeUserType)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeUserType,
    authenticatedUser,
    impersonatedUser?.userType,
    getTypesForUser,
  ])

  async function buyerSignUp({
    id,
    firstName,
    lastName,
    companyName,
    email,
    password,
    legacy,
    incentiveId,
  }: IBuyerSignUp) {
    await AuthService.buyerSignUp({
      id,
      firstName,
      lastName,
      companyName,
      email,
      password,
      legacy,
      incentiveId,
    })
  }

  async function vendorSignUp({
    id,
    firstName,
    lastName,
    companyName,
    email,
    password,
    legacy,
    promotionCodeId,
    offerId,
    partnerId,
    transactionId,
    incentiveId,
  }: IVendorSignUp) {
    await AuthService.vendorSignUp({
      id,
      firstName,
      lastName,
      companyName,
      email,
      password,
      legacy,
      promotionCodeId,
      offerId,
      partnerId,
      transactionId,
      incentiveId,
    })
  }

  async function confirmUser({
    firstName,
    lastName,
    email,
    code,
    userType,
  }: IConfirmUser) {
    await AuthService.confirmUser({
      firstName,
      lastName,
      email,
      code,
      userType,
    })
  }

  async function activateUser({ userType }: IActivateUser) {
    await AuthService.activateUser({ userType })
  }

  async function signIn({ email, password, userType }: ISignIn) {
    let signed = false

    try {
      setImpersonatedUser(undefined)
      sessionStorage.removeItem(SESSION_STORAGE_IMPERSONATION_KEY)

      const signInResponse = await Auth.signIn(email, password)

      changeActiveUserTypeForLocal(userType || activeUserType!)
      setTempCompletePasswordCognitoUser(signInResponse)

      disconnectFromChat()

      if (isNewPasswordRequired(signInResponse)) {
        const signupGroupUserAttribute = (
          signInResponse as IChallengeSignInResponse
        ).challengeParam.userAttributes?.[CustomUserAttribute.SIGNUP_GROUP]

        if (signupGroupUserAttribute && userType != signupGroupUserAttribute) {
          const signupUserGroupMismatchException = {
            code: exceptionsTypes.SIGNUP_USER_GROUP_MISMATCH_EXCEPTION,
          }

          throw signupUserGroupMismatchException
        }

        const newPasswordRequiredException = {
          code: exceptionsTypes.NEW_PASSWORD_REQUIRED_EXCEPTION,
        }

        throw newPasswordRequiredException
      }

      signed = true

      if (!isEmailVerified(signInResponse)) {
        if (userType === UserType.ADMIN) {
          await sendAdminVerifyCodeEmail({ email, password })
        }

        const userNotConfirmedException = {
          code: exceptionsTypes.USER_EMAIL_NOT_VERIFIED_EXCEPTION,
        }
        throw userNotConfirmedException
      }

      const groups = getTypesForUser(signInResponse)

      if (!groups || !groups.some((group: UserType) => group === userType)) {
        const hasAdminGroup = groups.some((group) => group === UserType.ADMIN)

        if (userType === UserType.ADMIN) {
          const userNotAdminException = {
            code: exceptionsTypes.USER_NOT_ADMIN_EXCEPTION,
            message: errorMessages[errorTypes.NOT_ADMIN_ACCOUNT],
          }

          throw userNotAdminException
        } else if (userType === UserType.BUYER) {
          const exception = hasAdminGroup
            ? {
                code: exceptionsTypes.HAS_ADMIN_ACCOUNT_EXCEPTION,
                message: errorMessages[errorTypes.HAS_ADMIN_ACCOUNT_CODE],
              }
            : {
                code: exceptionsTypes.USER_NOT_BUYER_EXCEPTION,
                message: errorMessages[errorTypes.NOT_BUYER_ACCOUNT],
              }
          throw exception
        } else {
          const exception = hasAdminGroup
            ? {
                code: exceptionsTypes.HAS_ADMIN_ACCOUNT_EXCEPTION,
                message: errorMessages[errorTypes.HAS_ADMIN_ACCOUNT_CODE],
              }
            : {
                code: exceptionsTypes.USER_NOT_VENDOR_EXCEPTION,
                message: errorMessages[errorTypes.NOT_VENDOR_ACCOUNT],
              }
          throw exception
        }
      }

      await activateUser({ userType })

      await fetchAuthenticatedUser()
      await removeSignupGroupAttributeIfExists({ signInResponse })
    } catch (err: any) {
      if (signed) {
        signOut()
      }

      handleAuthError(err)
    }
  }

  function isNewPasswordRequired(signInResponse: any) {
    return (
      signInResponse?.challengeName === cognitoChallenges.NEW_PASSWORD_REQUIRED
    )
  }

  function isEmailVerified(signInResponse: any) {
    return signInResponse?.getSignInUserSession?.().getIdToken?.().payload
      .email_verified
  }

  async function resendVerificationCodeEmail({
    email,
    userType,
  }: IResendVerificationEmail) {
    try {
      await AuthService.resendVerificationCodeToEmail({ email, userType })
    } catch (err: any) {
      handleAuthError(err)
    }
  }

  async function sendWelcomeEmails({
    cognitoUser,
  }: {
    cognitoUser: CognitoUser
  }) {
    const userRoles = getTypesForUser(cognitoUser)

    const isBuyer = userRoles.some((role) => role === UserType.BUYER)
    if (isBuyer) {
      await BuyerService.sendWelcomeEmail()
    }

    const isVendor = userRoles.some((role) => role === UserType.VENDOR)
    if (isVendor) {
      await VendorService.sendWelcomeEmail()
    }
  }

  async function completeNewPassword({ password }: ICompleteNewPassword) {
    try {
      if (!tempCompletePasswordCognitoUser) {
        throw new Error('Cognito user not found')
      }

      await Auth.completeNewPassword(tempCompletePasswordCognitoUser, password)
      await sendWelcomeEmails({ cognitoUser: tempCompletePasswordCognitoUser })

      setTempCompletePasswordCognitoUser(undefined)
    } catch (err: any) {
      handleAuthError(err)
    }
  }

  async function forgotPassword({ email, userType }: IForgotPassword) {
    try {
      await AuthService.forgotPassword({ email, userType })
    } catch (err) {
      handleAuthError(err)
    }
  }

  async function resetPassword({
    email,
    code,
    newPassword,
    userType,
  }: IResetPassword) {
    try {
      await AuthService.resetPassword({ email, code, newPassword, userType })
    } catch (err) {
      handleAuthError(err)
    }
  }

  async function sendVerifyCodeEmail({
    email,
    password,
  }: ISendVerifyCodeEmail = {}) {
    let signedIn = false

    try {
      if (!!email && !!password) {
        await Auth.signIn(email, password)
        signedIn = true
      }

      await Auth.verifyCurrentUserAttribute('email')
    } catch (err) {
      handleAuthError(err)
    } finally {
      if (signedIn) {
        await signOut()
      }
    }
  }

  async function sendAdminVerifyCodeEmail({
    email,
    password,
  }: ISendVerifyCodeEmail = {}) {
    let signedIn = false

    try {
      if (!!email && !!password) {
        await Auth.signIn(email, password)
        signedIn = true
      }

      await AuthService.sendVerifyCodeEmail({ userType: UserType.ADMIN })
    } catch (err) {
      handleAuthError(err)
    } finally {
      if (signedIn) {
        await signOut()
      }
    }
  }

  async function verifyEmail({ email, password, code }: IVerifyEmail) {
    let signedIn = false

    try {
      if (!!email && !!password) {
        await Auth.signIn(email, password)
        signedIn = true
      }

      await Auth.verifyCurrentUserAttributeSubmit('email', code)
    } catch (err: any) {
      handleAuthError(err)
    } finally {
      if (signedIn) {
        await signOut()
      }
    }
  }

  async function changePassword({
    userType,
    currentPassword,
    newPassword,
  }: IChangePassword) {
    await AuthService.changePassword({ userType, currentPassword, newPassword })
  }

  async function addUserType({
    convertingToUserType,
    email,
    password,
  }: IAddUserType): Promise<void> {
    if (convertingToUserType === UserType.BUYER) {
      await BuyerService.createBuyerFromVendor(
        {
          email,
          password,
        },
        {
          authenticate: isVendor(),
        }
      )
    } else if (convertingToUserType === UserType.VENDOR) {
      await VendorService.createVendorFromBuyer({
        email,
        password,
      })
    }
  }

  const handleAuthError = (err: any) => {
    if (Array.isArray(err)) {
      throw err
    }

    const Exception = exceptions[err.code] || Error
    throw new Exception(err?.message)
  }

  function isAdminAllowed({ action }: IIsAdminAllowed) {
    if (!admin) {
      return false
    }

    return AdminUtils.isAdminAllowed({ action, role: admin.role })
  }

  const switchToBuyerProfile = useCallback(
    async ({ redirect }: { redirect?: string } = {}) => {
      if (!authenticatedUser) {
        throw new Error('User not authenticated')
      }

      if (activeUserType !== UserType.VENDOR) {
        throw new Error('User is not a vendor')
      }

      try {
        const updatedAuthenticatedUser = await Auth.currentAuthenticatedUser({
          bypassCache: true,
        })

        const userGroups = getTypesForUser(updatedAuthenticatedUser)

        if (!userGroups.includes(UserType.BUYER)) {
          navigateTo({
            path: `/signin?redirect=${encodeURIComponent(
              redirect ?? window.location.pathname
            )}#buyers`,
            state: {
              convertToBuyer: true,
            },
          })
          return
        }

        changeActiveUserTypeForLocal(UserType.BUYER)
        setAuthenticatedUser(updatedAuthenticatedUser)
        disconnectFromChat()

        if (redirect) {
          window.location.href = `${window.location.origin}${redirect}`
          return
        }

        window.location.reload()
      } catch (err) {
        handleError({
          exception: err,
          options: {
            signOut,
          },
        })
      }
    },
    [
      authenticatedUser,
      activeUserType,
      getTypesForUser,
      changeActiveUserTypeForLocal,
      disconnectFromChat,
      navigateTo,
      handleError,
      signOut,
    ]
  )

  const updateVendorChatStatus = (
    data: Pick<IVendor, 'chatStatus' | 'chatStatusOfflineAutomaticResponse'>
  ) => {
    if (!isVendor()) {
      return
    }

    setVendor({
      ...vendor!,
      ...data,
    })
  }

  const updateAdminChatStatus = (
    data: Pick<IAdmin, 'chatStatus' | 'chatStatusOfflineAutomaticResponse'>
  ) => {
    if (!isAdmin()) {
      return
    }

    setAdmin({
      ...admin!,
      ...data,
    })
  }

  const removeSignupGroupAttributeIfExists = async ({
    signInResponse,
  }: {
    signInResponse: IAuthenticatedSignInResponse
  }) => {
    const signupGroupAttribute =
      signInResponse.attributes?.[CustomUserAttribute.SIGNUP_GROUP]

    if (!signupGroupAttribute) {
      return
    }

    await Auth.deleteUserAttributes(signInResponse, [
      CustomUserAttribute.SIGNUP_GROUP,
    ])
  }

  const setThirdPartyServiceUser = (
    thirdPartyServiceUser: IThirdPartyServiceUser
  ) => {
    const {
      id,
      userId,
      firstName,
      lastName,
      email,
      type,
      impersonatedBy: thirdPartyImpersonatedBy,
    } = thirdPartyServiceUser

    let impersonatedBy: ThirdPartyServiceUserImpersonatedBy | null = null

    if (thirdPartyImpersonatedBy) {
      const { userId, email, firstName, lastName, role } =
        thirdPartyImpersonatedBy

      impersonatedBy = { userId, email, firstName, lastName, role }
    }

    const currentUser = {
      id,
      userId,
      firstName,
      lastName,
      email,
      type,
      impersonatedBy,
    }

    Sentry.setUser(currentUser)
    datadogLogs.setUser(currentUser)
    datadogRum.setUser(currentUser)
  }

  async function saveProfile({
    firstName,
    lastName,
    officialTitle,
    personalEmail,
    phone,
    company,
    avatar,
  }: Omit<IUpdateProfile, 'avatar'> & {
    avatar?: File | null
  }) {
    const buildNewAvatar = ({
      newAvatarFile,
      oldAvatar,
    }: {
      newAvatarFile: File | null | undefined
      oldAvatar: string | undefined
    }): string | undefined => {
      if (newAvatarFile === undefined) {
        return oldAvatar
      } else if (newAvatarFile === null) {
        return undefined
      } else {
        return URL.createObjectURL(newAvatarFile)
      }
    }

    if (activeUserType === UserType.BUYER) {
      await BuyerService.updateProfile({
        firstName,
        lastName,
        officialTitle,
        personalEmail,
        phone,
        company,
        avatar,
      })
      setBuyer((v) => {
        if (!v) {
          return v
        }

        return {
          ...v,
          avatar: buildNewAvatar({
            newAvatarFile: avatar,
            oldAvatar: v.avatar,
          }),
          firstName,
          lastName,
          officialTitle,
          personalEmail,
          phone,
          company,
        }
      })
    } else {
      await VendorService.updateProfile({
        firstName,
        lastName,
        officialTitle,
        personalEmail,
        phone,
        avatar,
      })
      setVendor((v) => {
        if (!v) {
          return v
        }

        return {
          ...v,
          firstName,
          lastName,
          officialTitle,
          personalEmail,
          phone,
          avatar: buildNewAvatar({
            newAvatarFile: avatar,
            oldAvatar: v.avatar,
          }),
        }
      })
    }
  }

  return (
    <AuthContext.Provider
      value={{
        admin,
        buyer,
        vendor,
        isVisitor,
        isLoadingVendor,
        isLoadingBuyer,
        authenticatedUser,
        activeUserType,
        impersonatedUser,
        impersonatedBy,
        isLoadingImpersonation,
        isFetchingUserData,
        isSigningOut,
        refreshBuyerProfile,
        refreshVendorProfile,
        updateVendorChatStatus,
        updateAdminChatStatus,
        buyerSignUp,
        vendorSignUp,
        forgotPassword,
        resetPassword,
        changePassword,
        confirmUser,
        activateUser,
        resendVerificationCodeEmail,
        sendVerifyCodeEmail,
        sendAdminVerifyCodeEmail,
        saveProfile,
        saveAdminProfile,
        completeNewPassword,
        verifyEmail,
        addUserType,
        signIn,
        setIsSigningOut,
        signOut,
        isAdmin,
        isBuyer,
        isVendor,
        getCurrentUser,
        switchToBuyerProfile,
        isUserSoftDeleted,
        recoverUserAccount,
        changeActiveUserTypeForSession,
        changeActiveUserTypeForLocal,
        impersonate,
        isAdminAllowed,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthProvider }
