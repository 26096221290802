import { useEffect } from 'react'

import { INavigateToProps } from '@contexts/navigation'

import navigationHook from '@hooks/useNavigation'

const NavigateTo = (props: INavigateToProps) => {
  const { navigateTo } = navigationHook.useNavigation()

  useEffect(() => {
    navigateTo(props)
  })

  return null
}

export { NavigateTo }
