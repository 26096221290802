const buildScript = () => {
  const script = document.createElement('script')
  script.dataset.id = 'hs-script-loader'
  script.src = '//js.hs-scripts.com/43646120.js'
  script.async = true

  return script
}

export default { buildScript }
