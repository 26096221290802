const buildScript = () => {
  const ahrefKey = process.env.REACT_APP_AHREF_KEY

  if (!ahrefKey) {
    console.error('Ahref key is not defined')
    return null
  }

  const script = document.createElement('script')
  script.src = 'https://analytics.ahrefs.com/analytics.js'
  script.setAttribute('data-key', ahrefKey)
  script.async = true

  return script
}

export default { buildScript }
